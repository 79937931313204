<template>
  <v-container>
    <h2 class="mb-1">Leaderboard Info</h2>
    <v-row>
      <v-col class="d-flex">
        <p class="text-subtitle-1 font-weight-bold">
          Got questions? Call or Whatsapp {{ siteData.phone }}
        </p>
        <v-spacer></v-spacer>
        <v-btn to="/user/dashboard" color="primary">View Leaderboards</v-btn>
      </v-col>
    </v-row>
    <v-card
      v-for="post in posts"
      :key="post._id"
      flat
      rounded="lg"
      class="mt-5 mb-5"
    >
      <v-card-title>{{ post.title }}</v-card-title>
      <hr />
      <v-card-text>
        <v-img :src="post.image" aspect-ratio="1.5" contain class="mb-3" />
        <div v-html="post.content"></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import traderPost from '../../api/trader-post'

export default {
  name: 'UserTradersPost',
  data() {
    return {
      posts: {}
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.user,
      siteData: (state) => state.user.siteSettings
    })
  },
  created() {
    this.getCurrentPost()
  },
  methods: {
    async getCurrentPost() {
      const req = await traderPost.traderPost().getCurrentPost({ params: {} })
      this.posts = req.data.data.data
    }
  }
}
</script>

<style></style>
